import React, { useState } from 'react';
import '../styles/global.css'; // Import the global CSS styles

function CommaSeparatedToRows() {
  const [rowText, setRowText] = useState('');
  const [columnText, setColumnText] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setRowText(value);
  };

  const convertToRows = () => {
    const rows = rowText.split(',');
    const rowValues = rows.map((row) => row.trim());
    setColumnText(rowValues.join('\n'));
  };

  const clearFields = () => {
    setRowText('');
    setColumnText('');
  };

  return (
    <div className='comma-separated-to-rows-container'>
      <h2>Comma Separated To Rows</h2>

      <div className='conversion-inputs'>
        <div className='input-row'>
          <textarea
            className='row-input'
            placeholder='Enter comma-separated values'
            value={rowText}
            onChange={handleInputChange}
          ></textarea>

          <textarea
            className='column-output'
            placeholder='Rows'
            value={columnText}
            readOnly
          ></textarea>
        </div>

        <div className='button-container'>
          <button
            className='convert-button'
            onClick={convertToRows}
          >
            Convert
          </button>

          <button
            className='convert-button'
            onClick={clearFields}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommaSeparatedToRows;
