import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='home-container'>
      <h2>Welcome to the Tools App!</h2>
      <div className='tool-buttons'>
        <Link
          to='/column-to-rows'
          className='tool-button'
        >
          Columns to Comma Separated
        </Link>
        <Link
          to='/rows-to-columns'
          className='tool-button'
        >
          Comma Separated to Rows
        </Link>
        <Link
          to='/text-to-upper'
          className='tool-button'
        >
          Text To Upper
        </Link>
        <Link
          to='/text-to-lower'
          className='tool-button'
        >
          Text to Lower
        </Link>
        <Link
          to='/text-to-proper'
          className='tool-button'
        >
          Text To Proper
        </Link>
        <Link
          to='/jpg-to-png-converter'
          className='tool-button'
        >
          JPG To PNG
        </Link>
        <Link
          to='/jpg-to-webp-converter'
          className='tool-button'
        >
          JPG To WebP
        </Link>
        <Link
          to='/image-cropper'
          className='tool-button'
        >
          Image Cropper
        </Link>
      </div>
    </div>
  );
}

export default Home;
