// TextToUpper.js
import React, { useState } from 'react';
import '../styles/global.css'; // Import the global CSS styles

function TextToUpper() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputText(value);
  };

  const convertToUpperCase = () => {
    const upperCaseText = inputText.toUpperCase();
    setOutputText(upperCaseText);
  };

  const clearFields = () => {
    setInputText('');
    setOutputText('');
  };

  return (
    <div className='text-to-upper-container'>
      <h2>Convert Text To Upper Case</h2>

      <div className='conversion-inputs'>
        <div className='input-row'>
          <textarea
            className='input-text'
            placeholder='Enter text'
            value={inputText}
            onChange={handleInputChange}
          ></textarea>

          <textarea
            className='output-text'
            placeholder='Upper Case Text'
            value={outputText}
            readOnly
          ></textarea>
        </div>

        <div className='button-container'>
          <button
            className='convert-button'
            onClick={convertToUpperCase}
          >
            Convert
          </button>

          <button
            className='clear-button'
            onClick={clearFields}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

export default TextToUpper;
