import React, { useState } from 'react';
import '../styles/global.css'; // Import the global CSS styles

function ColumnToRows() {
  const [columnText, setColumnText] = useState('');
  const [rowText, setRowText] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setColumnText(value);
    convertToRow(value);
  };

  const convertToRow = (text) => {
    const columns = text.split('\n');
    const row = columns.map((column) => column.trim()).join(', ');
    setRowText(row);
  };

  return (
    <div className='column-to-rows-container'>
      <h2>Columns To Comma Separated</h2>

      <div className='conversion-inputs'>
        <div className='input-row'>
          <textarea
            className='column-input'
            placeholder='Enter columns (one per line)'
            value={columnText}
            onChange={handleInputChange}
          ></textarea>

          <textarea
            className='row-output'
            placeholder='Row'
            value={rowText}
            readOnly
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default ColumnToRows;
