import React, { useState } from 'react';
import Compressor from 'compressorjs';
import '../styles/jpgtowebpconverter.css';

function JPGToWebpConverter() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [conversionComplete, setConversionComplete] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setConversionComplete(false);
    setDownloadLink('');
  };

  const handleConvert = () => {
    if (!selectedFile) {
      console.error('No file selected.');
      return;
    }

    new Compressor(selectedFile, {
      quality: 0.8,
      success(result) {
        const downloadUrl = URL.createObjectURL(result);
        setDownloadLink(downloadUrl);
        setConversionComplete(true);
      },
      error(err) {
        console.error('Image compression failed:', err);
      },
    });
  };

  const handleClear = () => {
    setSelectedFile(null);
    setConversionComplete(false);
    setDownloadLink('');
  };

  return (
    <div className='jpg-to-webp-converter-container'>
      <h2>JPG to WebP Converter</h2>

      <div className='conversion-inputs'>
        <input
          type='file'
          accept='.jpg, .jpeg'
          className='input-file'
          onChange={handleFileSelect}
        />

        <div className='button-container'>
          <button
            className='convert-button'
            onClick={handleConvert}
          >
            Convert to WebP
          </button>

          {downloadLink && (
            <a
              href={downloadLink}
              download='converted_image.webp'
              className='download-button'
            >
              Download WebP
            </a>
          )}

          <button
            className='clear-button'
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      </div>

      {conversionComplete && (
        <div className='conversion-message'>
          Image converted to WebP. Click "Download WebP" to download.
        </div>
      )}
    </div>
  );
}

export default JPGToWebpConverter;

////////////////////////////////////////////////
//   Using nodejs to test compression         //
////////////////////////////////////////////////
// import React, { useState } from 'react';
// import axios from 'axios';
// import '../styles/jpgtowebpconverter.css';

// function JPGToWebpConverter() {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [conversionComplete, setConversionComplete] = useState(false);
//   const [downloadLink, setDownloadLink] = useState('');

//   const handleFileSelect = (event) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//     setConversionComplete(false);
//     setDownloadLink('');
//   };

//   const handleConvert = (event) => {
//     event.preventDefault(); // Prevent the default form submission behavior

//     if (!selectedFile) {
//       console.error('No file selected.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', selectedFile);

//     axios
//       .post('http://localhost:3001/compress-image', formData)
//       .then((response) => {
//         if (response.data.success) {
//           setDownloadLink(response.data.downloadLink);
//           setConversionComplete(true);

//           // Trigger the file download
//           const link = document.createElement('a');
//           link.href = response.data.downloadLink;
//           link.download = 'converted_image.webp';
//           link.click();
//         } else {
//           console.error(
//             'Image compression and conversion failed:',
//             response.data.message
//           );
//         }
//       })
//       .catch((error) => {
//         console.error(
//           'Image compression and conversion failed:',
//           error.message
//         );
//       });
//   };

//   const handleClear = () => {
//     setSelectedFile(null);
//     setConversionComplete(false);
//     setDownloadLink('');
//   };

//   return (
//     <div className='jpg-to-webp-converter-container'>
//       <h2>JPG to WebP Converter</h2>

//       <div className='conversion-inputs'>
//         <form onSubmit={handleConvert}>
//           <input
//             type='file'
//             accept='.jpg, .jpeg'
//             className='input-file'
//             onChange={handleFileSelect}
//           />

//           <div className='button-container'>
//             <button
//               type='submit'
//               className='convert-button'
//             >
//               Convert to WebP
//             </button>

//             {downloadLink && (
//               <a
//                 href={downloadLink}
//                 download='converted_image.webp'
//                 className='download-button'
//               >
//                 Download WebP
//               </a>
//             )}

//             <button
//               type='button'
//               className='clear-button'
//               onClick={handleClear}
//             >
//               Clear
//             </button>
//           </div>
//         </form>
//       </div>

//       {conversionComplete && (
//         <div className='conversion-message'>
//           Image converted to WebP. Click "Download WebP" to download.
//         </div>
//       )}
//     </div>
//   );
// }

// export default JPGToWebpConverter;
