import React, { useState } from 'react';
import '../styles/jpgtopngconverter.css';

function JpgToPngConverter() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [conversionComplete, setConversionComplete] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setConversionComplete(false);
    setDownloadLink('');
  };

  const handleConvert = () => {
    if (!selectedFile) {
      console.error('No file selected.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const pngDataUrl = canvas.toDataURL('image/png');
        setDownloadLink(pngDataUrl);
        setConversionComplete(true);
      };
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleClear = () => {
    setSelectedFile(null);
    setConversionComplete(false);
    setDownloadLink('');
  };

  return (
    <div className='jpg-to-png-converter-container'>
      <h2>JPG to PNG Converter</h2>

      <div className='conversion-inputs'>
        <input
          type='file'
          accept='.jpg, .jpeg'
          className='input-file'
          onChange={handleFileSelect}
        />

        <div className='button-container'>
          <button
            className='convert-button'
            onClick={handleConvert}
          >
            Convert to PNG
          </button>

          {downloadLink && (
            <a
              href={downloadLink}
              download='converted_image.png'
              className='download-button'
            >
              Download PNG
            </a>
          )}

          <button
            className='clear-button'
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      </div>

      {conversionComplete && (
        <div className='conversion-message'>
          Image converted to PNG. Click "Download PNG" to download.
        </div>
      )}
    </div>
  );
}

export default JpgToPngConverter;
