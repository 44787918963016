import React, { useState } from 'react';
import '../styles/global.css'; // Import the global CSS styles

function TextToProper() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputText(value);
    setOutputText(toProperCase(value));
  };

  const toProperCase = (text) => {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const clearFields = () => {
    setInputText('');
    setOutputText('');
  };

  return (
    <div className='text-to-proper-container'>
      <h2>Text To Proper Case</h2>

      <div className='conversion-inputs'>
        <div className='input-row'>
          <textarea
            className='input-text'
            placeholder='Enter text'
            value={inputText}
            onChange={handleInputChange}
          ></textarea>

          <textarea
            className='output-text'
            placeholder='Proper Case Text'
            value={outputText}
            readOnly
          ></textarea>
        </div>

        <div className='button-container'>
          <button
            className='clear-button'
            onClick={clearFields}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

export default TextToProper;
