import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './components/Home';
import RowsToColumns from './components/CommaSeparatedToRows';
import ColumnToRows from './components/ColumnToCommaSeparated';
import TextToUpper from './components/TextToUpper';
import TextToLower from './components/TextToLower';
import TextToProper from './components/TextToProper';
import JPGToPNGConverter from './components/JPGToPNGConverter';
import JPGToWebpConverter from './components/JPGToWebpConverter';
import ImageCropper from './components/ImageCropper';
import './styles/global.css';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        <header className='header'>
          <h1>Tools App</h1>
          <div className='header-buttons'>
            <Link
              to='/'
              className='home-button'
            >
              Home
            </Link>
          </div>
        </header>

        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/column-to-rows'
            element={<ColumnToRows />}
          />
          <Route
            path='/rows-to-columns'
            element={<RowsToColumns />}
          />
          <Route
            path='/text-to-upper'
            element={<TextToUpper />}
          />
          <Route
            path='/text-to-lower'
            element={<TextToLower />}
          />
          <Route
            path='/text-to-proper'
            element={<TextToProper />}
          />
          <Route
            path='/jpg-to-png-converter'
            element={<JPGToPNGConverter />}
          />
          <Route
            path='/jpg-to-webp-converter'
            element={<JPGToWebpConverter />}
          />
          <Route
            path='/image-cropper'
            element={<ImageCropper />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
