import React, { useRef, useState, useEffect } from 'react';
import '../ImageCropper.css';

function ImageCropper() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropRect, setCropRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragDirection, setDragDirection] = useState('');
  const [outputImage, setOutputImage] = useState(null);

  const imageRef = useRef(null);

  useEffect(() => {
    if (selectedImage) {
      setCropRect({
        x: 0,
        y: 0,
        width: selectedImage.width,
        height: selectedImage.height,
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage && cropRect.width > 0 && cropRect.height > 0) {
      const canvas = document.createElement('canvas');
      canvas.width = cropRect.width;
      canvas.height = cropRect.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        imageRef.current,
        cropRect.x,
        cropRect.y,
        cropRect.width,
        cropRect.height,
        0,
        0,
        cropRect.width,
        cropRect.height
      );

      const outputUrl = canvas.toDataURL();
      setOutputImage(outputUrl);
    }
  }, [selectedImage, cropRect]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        setSelectedImage(image);
      };

      image.onerror = () => {
        console.error('Failed to load the image.');
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleMouseDown = (event, direction) => {
    event.preventDefault();
    setIsDragging(true);
    setDragDirection(direction);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragDirection('');
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const { clientX, clientY } = event;
    const { top, left, width, height } =
      imageRef.current.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;

    switch (dragDirection) {
      case 'top':
        setCropRect((prevRect) => ({
          ...prevRect,
          y: Math.min(y, prevRect.y + prevRect.height - 10),
          height: Math.max(10, prevRect.y + prevRect.height - y),
        }));
        break;
      case 'right':
        setCropRect((prevRect) => ({
          ...prevRect,
          width: Math.max(10, x - prevRect.x),
        }));
        break;
      case 'bottom':
        setCropRect((prevRect) => ({
          ...prevRect,
          height: Math.max(10, y - prevRect.y),
        }));
        break;
      case 'left':
        setCropRect((prevRect) => ({
          ...prevRect,
          x: Math.min(x, prevRect.x + prevRect.width - 10),
          width: Math.max(10, prevRect.x + prevRect.width - x),
        }));
        break;
      default:
        break;
    }
  };

  const handleCropClick = () => {
    // Perform the crop operation here
    // You can access the cropped image data from the `outputImage` state
    console.log('Crop button clicked');
    console.log('Cropped image:', outputImage);
  };

  return (
    <div className='ImageCropper'>
      <h2>Image Cropper</h2>
      <div className='controls'>
        <input
          type='file'
          accept='image/*'
          onChange={handleImageChange}
        />
      </div>
      <div className='image-container'>
        {selectedImage && (
          <>
            <div
              className='crop-rect'
              style={{
                top: cropRect.y,
                left: cropRect.x,
                width: cropRect.width,
                height: cropRect.height,
              }}
            />

            <div
              className='resize-handle top'
              style={{
                top: cropRect.y - 5,
                left: cropRect.x + cropRect.width / 2,
              }}
              onMouseDown={(event) => handleMouseDown(event, 'top')}
            />

            <div
              className='resize-handle right'
              style={{
                top: cropRect.y + cropRect.height / 2,
                left: cropRect.x + cropRect.width - 5,
              }}
              onMouseDown={(event) => handleMouseDown(event, 'right')}
            />

            <div
              className='resize-handle bottom'
              style={{
                top: cropRect.y + cropRect.height - 5,
                left: cropRect.x + cropRect.width / 2,
              }}
              onMouseDown={(event) => handleMouseDown(event, 'bottom')}
            />

            <div
              className='resize-handle left'
              style={{
                top: cropRect.y + cropRect.height / 2,
                left: cropRect.x - 5,
              }}
              onMouseDown={(event) => handleMouseDown(event, 'left')}
            />

            <img
              className='image'
              src={selectedImage.src}
              alt='Selected'
              ref={imageRef}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            />
          </>
        )}
      </div>
      <div className='output'>
        {outputImage && (
          <>
            <h2>Output Image:</h2>
            <img
              className='output-image'
              src={outputImage}
              alt='Cropped'
            />
          </>
        )}
      </div>
      <button
        className='crop-button'
        onClick={handleCropClick}
        disabled={!outputImage}
      >
        Crop
      </button>
    </div>
  );
}

export default ImageCropper;
